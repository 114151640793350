import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

export const PaymentLoader = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment

  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        zIndex: 1000,
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ThreeCircles color='#6CBFC9' height={100} width={100} />
      <div className='text-primary-700 text-center font-semibold '>
        <div className='text-2xl'>Verarbeite Zahlung</div>
        Dies kann einen Augenblick dauern.
      </div>
    </div>
  );
};
