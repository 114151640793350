import React, { useEffect } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeIbanElementChangeEvent } from '@stripe/stripe-js';
import Url from 'url-parse';
import logo from './assets/edukonzept.png';
import { getCouponInfo, subscribeNewCustomer, useProductInfo } from './service/api';
import { InitialLoader, Modal, PaymentLoader } from './component';
import './App.scss';

type product = {
  id: string;
  name: string;
  price: string;
  rawPrice: number;
  interval: string;
  loading: boolean;
  error: string | boolean;
  description: string;
};

export function App() {
  const urlParams = new Url(window.location.href, true).query;
  const subscriptionPlanId = urlParams.subscriptionPlanId?.split(',') || [];

  const [externalSource, setExternalSource] = React.useState<string | undefined>(urlParams.externalSource);
  const [paymentVariant, setPaymentVariant] = React.useState('sepa');
  const [abo, setAbo] = React.useState(Array.isArray(subscriptionPlanId) ? subscriptionPlanId[0] : subscriptionPlanId);
  const [bankName, setBankName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<boolean | string>(false);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [city, setCity] = React.useState('');
  const [agb, setAgb] = React.useState(false);
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
  const [newPrice, setNewPrice] = React.useState<string | undefined>(undefined);
  const [amount, setAmount] = React.useState<string | undefined>(undefined);
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const products: product[] = [];

  const stripe = useStripe();
  const elements = useElements();

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    style: {
      base: {
        color: '#32325d',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    },
  };

  if (!subscriptionPlanId) {
    setError(true);
  }

  if (Array.isArray(subscriptionPlanId)) {
    subscriptionPlanId.forEach((id: string) => {
      const infos = useProductInfo(id ?? '');
      products.push({ id, ...infos });
    });
  }
  if (!Array.isArray(subscriptionPlanId)) {
    const infos = useProductInfo(subscriptionPlanId ?? '');
    products.push({ id: subscriptionPlanId, ...infos });
  }

  const sepaInputs = React.useRef<HTMLDivElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleChangeIban = (event: StripeIbanElementChangeEvent) => {
    setError(event.error?.message ?? false);
    setBankName(event.bankName ?? '');
  };

  const changePaymentVariant = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentVariant(e.target.value);
    if (e.target.value === 'sepa') {
      sepaInputs.current?.classList.add('SepaData-Active');
    }
    if (e.target.value === 'invoice') {
      sepaInputs.current?.classList.remove('SepaData-Active');
    }
  };

  const redeemCoupon = async () => {
    setAmount(undefined);
    setNewPrice(undefined);
    const couponInfosResponse = await getCouponInfo(formRef.current?.coupon.value.trim().toUpperCase());

    if (couponInfosResponse.error) {
      setError(couponInfosResponse.error);

      return;
    }

    if (products.length > 0) {
      const product = products.find((p) => p.id === abo);
      if (product) {
        if (couponInfosResponse.amount_off) {
          setNewPrice(`${(product.rawPrice - couponInfosResponse.amount_off) / 100} €`);
          setAmount(`${couponInfosResponse.amount_off / 100} €`);
        }
        if (couponInfosResponse.percent_off) {
          setNewPrice(`${(product.rawPrice - (product.rawPrice * couponInfosResponse.percent_off) / 100) / 100} €`);
          setAmount(`${couponInfosResponse.percent_off}%`);
        }
      }
    }
  };

  const changeAbo = (product: {
    price?: string;
    minimumSubscription?: number;
    name?: string;
    description?: string;
    interval: any;
    trialDays?: number;
    loading?: boolean;
    error?: boolean | string;
    id: any;
  }) => {
    setAbo(product.id);
    if (product.interval === 'month') {
      setPaymentVariant('sepa');
      if (!sepaInputs.current?.classList.contains('SepaData-Active')) {
        sepaInputs.current?.classList.add('SepaData-Active');
      }
    }
  };

  const closeModal = () => {
    setError(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setDisabled(true);
    window.scrollTo({ top: 0 });
    const target = e.target as typeof e.target & {
      abo: { value: string };
      mail: { value: string };
      phone: { value: string };
      firstname: { value: string };
      lastname: { value: string };
      accountHolder: { value: string };
      street: { value: string };
      city: { value: string };
      postalCode: { value: string };
      country: { value: string };
      organization: { value: string };
      paymentVariant: { value: string };
      privacyPolicies: { checked: boolean };
      agb: { checked: boolean };
      coupon: { value: string };
    };

    if (!target.privacyPolicies.checked || !target.agb.checked) {
      setIsLoading(false);
      setDisabled(false);
      setError('Bitte akzeptiere die AGB und Datenschutzbestimmungen.');

      return;
    }

    const ibanElement = elements!.getElement(IbanElement);
    if (!ibanElement) {
      setError(true);
      setDisabled(false);
      setIsLoading(false);

      return;
    }

    const fullName = `${target.firstname.value} ${target.lastname.value}`;
    const result = await subscribeNewCustomer(
      abo,
      fullName,
      target.firstname.value,
      target.lastname.value,
      target.accountHolder.value,
      target.mail.value,
      target.phone.value,
      target.street.value,
      target.city.value,
      target.postalCode.value,
      target.country.value,
      target.organization.value,
      ibanElement!,
      stripe!,
      target.paymentVariant.value,
      target.coupon.value,
      externalSource
    );

    if (result?.error) {
      setError(result.error);
      if (!externalSource && result.externalSource) {
        setExternalSource(result.externalSource);
      }
      setIsLoading(false);
      setDisabled(false);

      return;
    }

    const extra =
      target.paymentVariant.value === 'invoice' ? 'Die Rechnung kommt in der nächsten Stunde bei Ihnen an.' : '';

    alert(
      `Vielen Dank für Ihre Buchung. Bitte folge den Anweisungen in der Email die du in wenigen Minuten erhältst. ${extra}`
    );
    window.location.href = 'https://www.edukonzept.de/pages/lizenz-willkommen/';
    setIsLoading(false);
    setDisabled(false);
  };

  useEffect(() => {
    if (newPrice) {
      redeemCoupon().then();
    }
  }, [abo]);

  if (products.length === 0 || products[0].loading) {
    return <InitialLoader />;
  }

  return (
    <Container>
      {isLoading && <PaymentLoader />}
      {(products[0].error || error) && (
        <Modal title={''} handleClose={closeModal}>
          {error === true ? 'Es ist ein Fehler aufgetreten, versuchen sie es nocheinmal' : error}
        </Modal>
      )}
      <Row>
        <Col className={'text-center'}>
          <img className={'Logo'} src={logo} width={100} alt={'Edukonzept Logo'} />
        </Col>
      </Row>
      <Row className={'mt-1 mb-1'}>
        <Col className='text-center'>
          <div className={'Abo-Heading'}>{products[0]?.name}</div>
          <div className={'Abo-SubHeading'}>{products[0]?.description}</div>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Row>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h4>Abo:</h4>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <div className={'list-group list-group-checkable d-grid gap-2 border-0 w-auto'}>
              {products.map((product, index) => (
                <>
                  <input
                    style={{ display: 'none' }}
                    type={'radio'}
                    name={'abo'}
                    value={product.id}
                    className={'list-group-item-check pe-none'}
                    data-enpassusermodified='yes'
                    id={product.id}
                    key={product.id}
                    defaultChecked={index === 0}
                    onChange={() => changeAbo(product)}
                  />
                  <Form.Label
                    className={'list-group-item rounded-3 py-3'}
                    htmlFor={product.id}
                    key={`${product.id}-Interval`}
                  >
                    {product.price} - {/* eslint-disable-next-line no-nested-ternary */}
                    {product.interval === 'month'
                      ? 'Monatlich'
                      : product.interval === 'one_time'
                      ? 'Einmalig'
                      : 'Jährlich'}
                  </Form.Label>
                </>
              ))}
            </div>
          </Col>
        </Row>
        <Row className={'mt-3 mb-3'}>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h4>Ihre Daten:</h4>
          </Col>
        </Row>
        <Row className={'mt-1 mb-1'}>
          <Col sm={12} m={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicFirstname'>
              <Form.Label>Vorname *</Form.Label>
              <Form.Control
                type='text'
                placeholder='Max'
                name='firstname'
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 5, offset: 1 }} lg={{ span: 4 }}>
            <Form.Group className='mb-3' controlId='formBasicLastname'>
              <Form.Label>Nachname *</Form.Label>
              <Form.Control
                type='text'
                placeholder='Mustermann'
                name='lastname'
                onChange={(event) => setLastName(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>E-Mail *</Form.Label>
              <Form.Control
                type='email'
                placeholder='max.mustermann@example.de'
                name='mail'
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 5, offset: 1 }} lg={{ span: 4 }}>
            <Form.Group className='mb-3' controlId='formBasicPhone'>
              <Form.Label>Telefonnummer *</Form.Label>
              <Form.Control
                type='text'
                placeholder='0203 1234567'
                name='phone'
                onChange={(event) => setPhone(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={'mt-3 mb-3'}>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h4>Rechnungsadresse:</h4>
          </Col>
        </Row>
        <Row className={'mt-1 mb-1'}>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicOrganization'>
              <Form.Label>Name der Einrichtung (optional)</Form.Label>
              <Form.Control type='text' placeholder='Die kleinen Strolche' name='organization' />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicStreet'>
              <Form.Label>Straße *</Form.Label>
              <Form.Control
                type='text'
                placeholder='Musterstraße 123'
                name='street'
                onChange={(event) => setStreet(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicPostalCode'>
              <Form.Label>Postleitzahl *</Form.Label>
              <Form.Control
                type='text'
                placeholder='12345'
                name='postalCode'
                onChange={(event) => setPostalCode(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 5, offset: 1 }} lg={{ span: 4 }}>
            <Form.Group className='mb-3' controlId='formBasicCity'>
              <Form.Label>Ort/Stadt *</Form.Label>
              <Form.Control
                type='text'
                placeholder='Musterstadt'
                name='city'
                onChange={(event) => setCity(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicCountry'>
              <Form.Label>Land</Form.Label>
              <Form.Select name={'country'}>
                <option value='DE' defaultValue={'DE'}>
                  Deutschland
                </option>
                <option value='AT'>Österreich</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h4>Bezahlvariante:</h4>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <div className={'list-group list-group-checkable d-grid gap-2 border-0 w-auto'}>
              <input
                style={{ display: 'none' }}
                type={'radio'}
                name={'paymentVariant'}
                value={'sepa'}
                className={'list-group-item-check pe-none'}
                data-enpassusermodified='yes'
                id={'sepa'}
                checked={paymentVariant === 'sepa'}
                onChange={changePaymentVariant}
              />
              <Form.Label className={'list-group-item rounded-3 py-3'} htmlFor={'sepa'}>
                Lastschrift
              </Form.Label>
              {abo === products.find((p) => p.interval === 'year' || p.interval === 'one_time')?.id && (
                <>
                  <input
                    style={{ display: 'none' }}
                    type={'radio'}
                    name={'paymentVariant'}
                    value={'invoice'}
                    className={'list-group-item-check pe-none'}
                    data-enpassusermodified='yes'
                    id={'invoice'}
                    checked={paymentVariant === 'invoice'}
                    onChange={changePaymentVariant}
                  />
                  <Form.Label className={'list-group-item rounded-3 py-3'} htmlFor={'invoice'}>
                    Rechnung
                  </Form.Label>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row ref={sepaInputs} className={'SepaData SepaData-Active'}>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicAccountHolder'>
              <Form.Label>Kontoinhaber (falls abweichend)</Form.Label>
              <Form.Control type='text' placeholder='Max Mustermann' name='accountHolder' />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicIban'>
              <Form.Label>IBAN *</Form.Label>
              <IbanElement id='iban-element' options={IBAN_ELEMENT_OPTIONS} onChange={handleChangeIban} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h4>Zusätzliches:</h4>
          </Col>
          <Col sm={11} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicAccountHolder'>
              <Form.Label>Gutscheincode</Form.Label>
              <InputGroup>
                <Form.Control type='text' placeholder='' name='coupon' />
                <Button onClick={redeemCoupon} style={{ color: 'white' }}>
                  Einlösen
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
          {newPrice && (
            <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
              <p className={'text-muted'}>Rabatt von {amount} auf den Preis pro Abrechnungszeitraum.</p>
              <p className={'text-muted'}>
                Ihr neuer Preis ist: <strong>{newPrice}</strong>
              </p>
            </Col>
          )}
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicPrivacyPolicies'>
              <Form.Check
                type='checkbox'
                name={'privacyPolicies'}
                onChange={(event) => setPrivacyPolicy(event.target.checked)}
                required
                label={
                  <>
                    Ich stimme den{' '}
                    <a href={'https://www.edukonzept.de/pages/datenschutz'} target={'_blank'}>
                      Datenschutzbestimmungen
                    </a>{' '}
                    zu. *
                  </>
                }
              />
            </Form.Group>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form.Group className='mb-3' controlId='formBasicAGB'>
              <Form.Check
                type='checkbox'
                name={'agb'}
                onChange={(event) => setAgb(event.target.checked)}
                required
                label={
                  <>
                    Ich stimme den{' '}
                    <a href={'https://www.edukonzept.de/pages/agb'} target={'_blank'}>
                      AGBs
                    </a>{' '}
                    zu. *
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={'mt-3 mb-5'}>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <p className={'text-muted'}>* - Pflichteingaben</p>
          </Col>
          <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <div className={'d-grid gap-2'}>
              <Button
                variant='secondary'
                type='submit'
                disabled={
                  firstName === '' ||
                  lastName === '' ||
                  email === '' ||
                  street === '' ||
                  postalCode === '' ||
                  city === '' ||
                  phone === '' ||
                  !agb ||
                  !privacyPolicy ||
                  disabled
                }
                style={{ color: 'white' }}
              >
                Zahlungspflichtig bestellen
              </Button>
            </div>
          </Col>
        </Row>
        {paymentVariant === 'sepa' && (
          <Row>
            <Col sm={12} m={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
              <p className={'text-muted'}>
                Durch Angabe der IBAN und Bestätigung der Zahlung ermächtige ich/ermächtigen wir (A) Edukonzept und
                Stripe, unseren Zahlungsdienstleister, und/oder PPRO, den lokalen Zahlungsdienstleister von Stripe,
                Zahlungen von meinem/unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise ich mein/weisen
                wir unser Kreditinstitut gemäß diesen Anweisungen an, die auf mein/unser Konto gezogenen Lastschriften
                einzulösen. Hinweis: Ich kann/Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum,
                die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/unserem Kreditinstitut
                vereinbarten Bedingungen.
              </p>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
}
