import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

export const InitialLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ThreeCircles color='#6CBFC9' height={100} width={100} />
      <span className='text-primary-700 text-center font-semibold text-2xl'>Lade Bestellformular</span>
    </div>
  );
};
