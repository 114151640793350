import { useEffect, useState } from 'react';
import type { Stripe, StripeIbanElement } from '@stripe/stripe-js';

// todo
const API_URL = 'https://checkout.edukonzept.de';
// const API_URL = 'https://gitarrissimo-dev.herokuapp.com';
// const API_URL = 'http://localhost:3000';
const headers = {
  'Content-Type': 'application/json',
};

export const subscribeNewCustomer = async (
  subscriptionPlanId: string,
  fullname: string,
  firstname: string,
  lastname: string,
  accountholder: string,
  mail: string,
  telephone: string,
  customerAddress: string,
  customerCity: string,
  customerPostcode: string,
  customerCountry: string,
  customerOrganization: string | undefined,
  ibanElement: StripeIbanElement,
  stripe: Stripe,
  paymentMethod: string,
  customerCoupon: string | undefined,
  externalsource: string | undefined

  // eslint-disable-next-line max-params
) => {
  const fullName = fullname.trim();
  const firstName = firstname.trim();
  const lastName = lastname.trim();
  const accountHolder = accountholder.trim();
  const email = mail.trim();
  const phone = telephone.trim();
  const address = customerAddress.trim();
  const city = customerCity.trim();
  const postcode = customerPostcode.trim();
  const organization = customerOrganization?.trim();
  let coupon = customerCoupon?.trim().toUpperCase();

  if (coupon === '') {
    coupon = undefined;
  }

  if (coupon && coupon !== '') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const couponResult = await getCouponInfo(coupon);
    if (couponResult.error) {
      return { error: 'Dieser Coupon existiert nicht. Bitte korrigieren oder ohne Coupon bestellen.' };
    }
  }

  const result = await fetch(`${API_URL}/initiate-subscription`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      subscriptionPlanId,
      firstName,
      lastName,
      email,
      phone,
      address,
      city,
      postcode,
      country: customerCountry,
      organization,
      externalSource: externalsource,
      paymentMethod,
    }),
  });

  if (!result.ok) {
    return { error: 'Es ist ein Fehler aufgetreten', externalSource: externalsource };
  }

  const response = await result.json();

  if (response.error) {
    return { error: response.error, externalSource: externalsource };
  }
  const { externalSource } = response;

  const finalizeObject = {
    subscriptionPlanId,
    email,
    externalSource,
    paymentMethod,
    coupon,
  };

  if (paymentMethod === 'sepa') {
    const secret = response.clientSecret;
    const confirmation = await stripe.confirmSepaDebitSetup(secret, {
      payment_method: {
        sepa_debit: ibanElement,
        billing_details: {
          name: accountHolder.length > 0 ? accountHolder : fullName,
          email,
        },
      },
    });

    if (confirmation.error) {
      return { error: confirmation.error.message, externalSource };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    finalizeObject.paymentMethodId = confirmation.setupIntent?.payment_method;
  }

  const finalizeResult = await fetch(`${API_URL}/finalize-subscription`, {
    method: 'POST',
    headers,
    body: JSON.stringify(finalizeObject),
  });

  if (!finalizeResult.ok) {
    console.log(finalizeResult);

    return { error: 'Es ist ein Fehler aufgetreten', externalSource };
  }

  return { success: true, newUser: response.newUser };
};

export const getProductInfo = async (subscriptionPlanId: string) => {
  const result = await fetch(`${API_URL}/product-info`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ subscriptionPlanId }),
  });

  if (!result.ok) {
    throw new Error('Es ist ein Fehler aufgetreten');
  }

  const { minimumSubscription, price, rawPrice, productName, interval, trialDays, description } = await result.json();

  return { minimumSubscription, price, rawPrice, productName, interval, trialDays, description };
};

export const useProductInfo = (subscriptionPlanId: string) => {
  const [price, setPrice] = useState('');
  const [rawPrice, setRawPrice] = useState(0);
  const [minimumSubscription, setMinimumSubscription] = useState(0);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [trialDays, setTrialDays] = useState(0);
  const [interval, setInterval] = useState('');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getProductInfo(subscriptionPlanId)
      .then((res) => {
        setPrice(res.price);
        setRawPrice(res.rawPrice);
        setMinimumSubscription(res.minimumSubscription);
        setProductName(res.productName);
        setProductDescription(res.description);
        setInterval(res.interval);
        if (res.trialDays) {
          setTrialDays(res.trialDays);
        }
      })
      .catch((err) => {
        // todo log
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    price,
    rawPrice,
    minimumSubscription,
    name: productName,
    description: productDescription,
    interval,
    trialDays,
    loading,
    error,
  } as {
    price: string;
    rawPrice: number;
    minimumSubscription: number;
    name: string;
    description: string;
    interval: string;
    trialDays: number;
    loading: boolean;
    error: boolean;
  };
};

export const getCouponInfo = async (couponId: string) => {
  const result = await fetch(`${API_URL}/coupon/${couponId}`, {
    method: 'GET',
    headers,
  });

  if (!result.ok) {
    return { error: 'Coupon existiert nicht!' };
  }

  // eslint-disable-next-line camelcase
  const { amount_off, percent_off } = await result.json();

  // eslint-disable-next-line camelcase
  return { error: false, amount_off, percent_off };
};
