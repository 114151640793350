import React from 'react';
import { Modal as BSModal } from 'react-bootstrap';

type ModalProps = {
  title: string;
  children: React.ReactNode;
  handleClose: () => void;
};

export const Modal = ({ title, children, handleClose }: ModalProps): JSX.Element => {
  return (
    <BSModal show={true} onHide={handleClose}>
      <BSModal.Header closeButton>
        <BSModal.Title>{title}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <p>{children}</p>
      </BSModal.Body>
    </BSModal>
  );
};

/*
*
*
* <div className='modal fade show' id='exampleModal' aria-labelledby='exampleModalLabel'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              {title}
            </h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
* */
